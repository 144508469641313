<template>
  <div class="app-input" :style="{ '--input-hover-color': `var(--${color})` }">
    <label :for="name" class="app-input-label">
      <span>{{ label }}</span>
      <!-- Required Asterisk -->
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <div class="app-input-container">
      <!-- Prepend Icon -->
      <div class="app-input-icon-prepend">
        <slot name="prepend-icon">
          <AppIcon v-if="prependIcon" :name="prependIcon"></AppIcon>
        </slot>
      </div>
      <!-- Input Element -->
      <DatePicker
        v-model="innerVal"
        class="w-100 border-none"
        v-bind="$attrs"
        :type="noTime ? 'date' : 'datetime'"
        :name="name"
        :format="noTime ? 'MMM D, YYYY' : 'MMM D, YYYY hh:mm A'"
        :value-type="noTime ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm'"
        :disabled-date="minToday ? notBeforeToday : () => false"
        @focus="focus = true"
        @blur="focus = false"
      />
      <!-- Form Validation Input (maintain app-form-validation class) -->
      <input
        class="app-form-validation"
        type="hidden"
        :value="allValidationsPassed"
      />
      <!-- Append Icon -->
      <div class="app-input-icon-prepend">
        <slot name="append-icon">
          <AppIcon v-if="appendIcon" :name="appendIcon"></AppIcon>
        </slot>
      </div>
    </div>
    <!-- Error Message -->
    <span v-if="!hideError" class="app-input-error">
      {{ error }}
    </span>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import _appErrorValidation from '@/shared/mixins/_appErrorValidation';
import AppIcon from './AppIcon.vue';
import { DateTime } from 'luxon';

export default {
  name: 'AppInputDateTime',

  components: { AppIcon, DatePicker },

  mixins: [_appDefaultInput, _appErrorValidation],

  props: {
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    prependIcon: { type: String, default: '' },
    appendIcon: { type: String, default: '' },
    color: { type: String, default: 'primary' },
    hideError: { type: Boolean, default: false },
    validations: { type: Array, default: () => [] },
    validate: { type: [Number, String, Boolean], default: false },
    noTime: { type: Boolean, default: false },
    minToday: { type: Boolean, default: false },
  },

  data() {
    return {
      focus: false,
      today: DateTime.now(),
    };
  },

  watch: {
    innerVal() {
      this.validateInput();
    },
  },

  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/theme/_inputs';
@import '@/scss/theme/_colors';
// $default-color: red;
$primary-color: map-get(
  $map: $colors,
  $key: 'primary',
);
@import '~vue2-datepicker/scss/index.scss';
</style>
