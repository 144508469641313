<template>
  <div class="overflow-hidden w-100">
    <!-- Products Row -->
    <!-- Headers -->
    <div class="row no-gutters">
      <div class="col-6 d-none d-md-block">
        <h4 class="mt-4 font-weight-4">PRODUCT SUMMARY</h4>
        <div class="bg-gray-light-1 p-2 pl-3 pr-3">
          <div class="row">
            <div class="col">Product</div>
            <div class="col">Variants</div>
            <div class="col">Qty</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <h4 class="col-6 mt-4 font-weight-4 d-none d-md-block">PROPOSAL</h4>
        <h4 class="col-12 pl-0 mt-4 font-weight-4 bg-gray-light-3 d-md-none">
          PROPOSAL
        </h4>
        <div class="bg-primary p-2 pl-3 pr-3 d-none d-md-block">
          <div class="row">
            <div class="col-3">Product</div>
            <div class="col-3">Variants</div>
            <div class="col-2">Qty</div>
            <div class="col-2">Price</div>
            <div class="col-2">Total</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Product List -->
    <div
      v-for="(product, i) in products"
      :key="`test-2-${i}`"
      class="row no-gutters mb-2"
    >
      <!-- Product Card -->
      <div class="col-md-6 d-none d-md-block">
        <AppCard class="p-3 rounded-0 h-100">
          <!-- Details -->
          <div class="row">
            <div class="col-4 d-flex align-items-center">
              <AppMediaViewer
                width="40"
                aspect-ratio="1/1"
                :src="product.product | thumbnail"
              />
              <span class="ml-2 text-body-2 font-weight-5 text-truncate">
                {{ product.product_name }}
              </span>
            </div>
            <div class="col-4 d-flex align-items-center">
              {{ product.variant ? product.variant.name : 'N/A' }}
            </div>
            <div class="col-4 d-flex align-items-center">
              <div v-if="product.quantity_description">
                <span>
                  {{
                    `${product.quantity} ${product.quantity_description.quantity.name}`
                  }}
                </span>
                <span class="d-inline-block text-caption"
                  >({{
                    `${
                      parseInt(product.quantity) *
                      product.quantity_description.conversion
                    } pieces`
                  }})</span
                >
              </div>
              <span v-else>{{ `${product.quantity} pcs` }}</span>
            </div>
          </div>
          <!-- Notes -->
          <p v-if="product.note" class="text-small mt-3">
            <span class="font-weight-5">Notes:</span>
            <span>
              {{ product.note }}
            </span>
          </p>
        </AppCard>
      </div>
      <!-- Proposal Card -->
      <div class="col-12 col-md-6">
        <!-- Web View -->
        <AppCard
          class="
            d-none d-md-block
            mb-2
            p-3
            rounded-0
            h-100
            border-left border-primary
          "
        >
          <div v-if="proposedProducts[i]">
            <!-- Details -->
            <div class="row mt-2">
              <div class="col-3 d-flex align-items-center">
                <span class="text-body-2 font-weight-5 text-truncate">
                  {{ proposedProducts[i].product.name }}
                </span>
              </div>
              <div class="col-3 d-flex align-items-center">
                <p class="m-0">
                  {{
                    proposedProducts[i].variant
                      ? proposedProducts[i].variant.name
                      : 'N/A'
                  }}
                </p>
              </div>
              <div class="col-2 d-flex align-items-center">
                <p class="text-truncate m-0">
                  {{ `${proposedProducts[i].quantity} pcs` }}
                </p>
              </div>
              <div class="col-2 d-flex align-items-center">
                <p class="text-truncate m-0">
                  $ {{ proposedProducts[i].price }}
                </p>
              </div>
              <div class="col-2 d-flex align-items-center">
                <p class="text-truncate m-0">
                  $
                  {{ proposedProducts[i].price * proposedProducts[i].quantity }}
                </p>
              </div>
            </div>
            <div class="mt-auto mb-2 d-flex justify-content-between">
              <!-- Attach File -->
              <AppBtn
                v-if="proposedProducts[i].product.files.length > 0"
                text
                prependIcon="document_attachment"
                color="dark"
                class="pl-0 text-body-3"
                @click="viewAttachments(proposedProducts[i].product.files)"
              >
                {{ proposedProducts[i].product.files.length }} Attached File
              </AppBtn>
              <!-- Propose Button -->
              <AppBtn
                v-if="!quote || (quote && edit)"
                text
                prependIcon="edit"
                color="info"
                class="ml-auto text-body-3"
                @click="
                  proposeProductForOrder(
                    proposedProducts[i],
                    i,
                    proposedProducts[i].product.id
                  )
                "
              >
                Edit Proposed Product
              </AppBtn>
            </div>
          </div>
          <div
            v-else
            class="
              h-100
              d-flex
              flex-column flex-md-row
              align-items-center
              justify-content-center
            "
          >
            <AppIcon
              name="search"
              class="rounded-circle bg-gray-light-4"
            ></AppIcon>
            <div
              class="
                d-flex
                flex-column flex-md-row
                align-items-center align-items-center
              "
            >
              <p class="m-0 mr-1">No product on your list matches the order</p>
              <p class="m-0 font-weight-6">{{ product.product_name }}</p>
              <AppBtn
                text
                color="info"
                class="ml-1 p-0 d-none d-md-block"
                @click="proposeProductForOrder(product)"
              >
                Choose Manually
              </AppBtn>
            </div>
          </div>
        </AppCard>
        <!-- Mobile View -->
        <AppCard
          class="d-md-none mb-2 p-3 rounded-0 h-100 border-left border-primary"
        >
          <div v-if="proposedProducts[i]" class="mt-2">
            <div class="d-flex">
              <!-- Details -->
              <div class="mr-2">
                <AppMediaViewer
                  width="42px"
                  :src="proposedProducts[i].product | thumbnail"
                ></AppMediaViewer>
              </div>
              <div class="row">
                <div class="col-12 d-flex align-items-center">
                  <span class="text-body-2 font-weight-5 text-truncate">
                    {{ proposedProducts[i].product.name }}
                  </span>
                </div>
                <div class="col-12 d-flex align-items-center">
                  <p class="m-0">
                    {{
                      proposedProducts[i].variant
                        ? proposedProducts[i].variant.name
                        : 'N/A'
                    }}
                    <!-- <span>Thickness:</span>
                    <span class="ml-2 text-body-2 font-weight-5">3/4”</span> -->
                  </p>
                </div>
                <div class="col-6 d-flex align-items-center">
                  <p class="text-truncate m-0">
                    x {{ `${proposedProducts[i].quantity} pcs` }}
                    <!-- <span>3 Bundle</span> -->
                    <!-- <span class="ml-2 text-body-2 font-weight-5">(32 pcs)</span> -->
                  </p>
                </div>
                <div
                  class="col-6 d-flex align-items-center justify-content-end"
                >
                  <p class="m-0">$ {{ proposedProducts[i].price }}</p>
                </div>
                <div
                  class="col-12 d-flex align-items-center justify-content-end"
                >
                  <p class="text-truncate m-0">
                    <span>SUBTOTAL: </span>
                    <span class="font-weight-6">
                      $
                      {{
                        proposedProducts[i].price * proposedProducts[i].quantity
                      }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-auto mb-2 d-flex flex-column">
              <!-- Attach File -->
              <AppBtn
                v-if="proposedProducts[i].product.files.length > 0"
                text
                prependIcon="document_attachment"
                color="dark"
                class="pl-0 mr-auto text-body-3"
                @click="viewAttachments(proposedProducts[i].product.files)"
              >
                {{ proposedProducts[i].product.files.length }} Attached File
              </AppBtn>
              <!-- Propose Button -->
              <AppBtn
                v-if="!quote || (quote && edit)"
                text
                color="info"
                class="mt-3 mr-auto text-body-3 p-0"
                :prependIcon="quote ? 'edit' : 'visible'"
                @click="
                  proposeProductForOrder(
                    proposedProducts[i],
                    i,
                    proposedProducts[i].product.id
                  )
                "
              >
                {{
                  quote ? 'Edit Proposed Product' : 'Compare to customer order'
                }}
              </AppBtn>
            </div>
          </div>
          <div
            v-else
            class="
              h-100
              d-flex
              flex-column flex-md-row
              align-items-center
              justify-content-center
            "
          >
            <AppIcon
              name="search"
              class="rounded-circle bg-gray-light-4"
            ></AppIcon>
            <div
              class="
                d-flex
                flex-column flex-md-row
                align-items-center align-items-center
              "
            >
              <p class="m-0 mr-1">No product on your list matches the order</p>
              <p class="m-0 font-weight-6">{{ product.product_name }}</p>
            </div>
            <!-- Mobile view add product proposal button -->
            <AppBtn
              text
              color="info"
              class="mt-2 mr-auto p-0 d-md-none"
              @click="proposeProductForOrder(product)"
              prependIcon="visible"
            >
              View customer order
            </AppBtn>
          </div>
        </AppCard>
      </div>
    </div>
    <AppCard class="mb-2 d-md-none">
      <div class="p-2 pl-3 pr-3 d-flex">
        <span class="text-primary">Order Total</span>
        <span class="ml-auto w-50 text-right font-weight-5">
          $ {{ total.productTotal | toMoney }}
        </span>
      </div>
    </AppCard>

    <!-- Delivery Row -->
    <div class="row no-gutters">
      <h4 class="col-12 col-md-6 mt-4 font-weight-4">DELIVERY</h4>
      <h4 class="col-6 mt-4 font-weight-4 d-none d-md-block">DELIVERY FEE</h4>
    </div>

    <AppCard class="p-0 rounded-0">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 p-3">
          <div class="row">
            <!-- Delivery Type -->
            <AppTextLabeled class="col-12 col-md-6" label="DELIVERY OPTIONS">
              {{
                order.delivery_type === 'pick up'
                  ? 'Pick up'
                  : 'Ship to address'
              }}
            </AppTextLabeled>
            <!-- Has Forklift -->
            <AppTextLabeled class="col-12 col-md-6" label="SPECIFIC TRUCK">
              <span v-if="order.has_forklift">Piggy Back Forklift</span>
              <ul v-if="order.is_union_delivery" class="pl-4 d-inline-block">
                <li>Union Delivery</li>
              </ul>
            </AppTextLabeled>
          </div>
          <!-- delivery address -->
          <AppTextLabeled class="mt-2" label="ADDRESS">
            {{ order | fullAddress }}
          </AppTextLabeled>
        </div>
        <!-- Web View -->
        <div
          class="
            d-none d-md-block
            col-12 col-md-6
            p-3
            border-left border-primary
          "
        >
          <div class="d-flex justify-content-between align-content-center">
            <p class="text-body-3 d-flex align-items-center font-weight-4">
              DELIVERY DATE
            </p>
            <AppInputDateTime
              v-model="deliveryDate"
              noTime
              minToday
              hideError
              class="w-50 flex-shrink-1 mb-3"
              :readOnly="!(!quote || (quote && edit))"
            ></AppInputDateTime>
          </div>

          <div class="d-flex justify-content-between align-content-center">
            <p class="text-body-3 d-flex align-items-center font-weight-4">
              SHIPPING COST
            </p>
            <AppInputCurrency
              v-model="shippingFee"
              hideError
              placeholder="00.0"
              class="w-50 flex-shrink-1 mb-3"
              :readOnly="!(!quote || (quote && edit))"
            ></AppInputCurrency>
          </div>
          <div class="d-flex justify-content-between align-content-center">
            <p class="text-body-3 d-flex align-items-center font-weight-4">
              TRUCK FEE
            </p>
            <AppInputCurrency
              v-model="truckFee"
              hideError
              placeholder="00.0"
              class="w-50 flex-shrink-1 mb-3"
              :readOnly="!(!quote || (quote && edit))"
            ></AppInputCurrency>
          </div>
          <div class="d-flex justify-content-between align-content-center">
            <p class="text-body-3 d-flex align-items-center font-weight-4">
              TOTAL
            </p>
            <p
              class="
                w-25
                p-2
                pl-3
                text-body-3
                d-flex
                align-items-center
                font-weight-4
              "
            >
              $ {{ total.totalDelivery | toMoney }}
            </p>
          </div>
        </div>
        <!-- Mobile View -->
        <div class="d-md-none col-12 col-md-6 p-3 border-left border-primary">
          <div
            class="
              d-md-none d-flex
              justify-content-between
              align-content-center
            "
          >
            <AppInputDateTime
              v-model="deliveryDate"
              noTime
              minToday
              hideError
              class="w-100 flex-shrink-1 mb-3"
              label="Delivery Date"
              :readOnly="!(!quote || (quote && edit))"
            ></AppInputDateTime>
          </div>

          <div class="d-flex justify-content-between align-content-center">
            <AppInputCurrency
              v-model="shippingFee"
              hideError
              placeholder="00.0"
              class="w-100 flex-shrink-1 mb-3"
              label="Shipping Cost"
              :readOnly="!(!quote || (quote && edit))"
            ></AppInputCurrency>
          </div>
          <div class="d-flex justify-content-between align-content-center">
            <AppInputCurrency
              v-model="truckFee"
              hideError
              placeholder="00.0"
              class="w-100 flex-shrink-1 mb-3"
              label="TRUCK FEE"
              :readOnly="!(!quote || (quote && edit))"
            ></AppInputCurrency>
          </div>
          <div class="d-flex justify-content-between align-content-center">
            <p
              class="
                text-body-2 text-primary
                d-flex
                align-items-center
                font-weight-3
              "
            >
              Delivery Fee Total
            </p>
            <p class="w-50 p-2 pl-3 text-body-3 text-right font-weight-4">
              $ {{ total.totalDelivery | toMoney }}
            </p>
          </div>
        </div>
      </div>
    </AppCard>

    <!-- Total Cost Row -->
    <div class="mt-4 row no-gutters">
      <div class="col-12 col-md-6 offset-md-6">
        <AppCard class="p-3">
          <div class="d-flex justify-content-between align-content-center">
            <span class="text-caption d-flex align-items-center font-weight-4">
              MATERIALS TOTAL AMOUNT
            </span>
            <span class="w-50 p-2 pl-3 text-body-3 text-right font-weight-4">
              $ {{ total.productTotal | toMoney }}
            </span>
          </div>
          <div class="d-flex justify-content-between align-content-center">
            <span class="text-caption d-flex align-items-center font-weight-4">
              TOTAL DELIVERY FEE
            </span>
            <span class="w-50 p-2 pl-3 text-body-3 text-right font-weight-4">
              $ {{ total.totalDelivery | toMoney }}
            </span>
          </div>
          <div class="d-flex justify-content-between align-content-center">
            <span class="text-caption d-flex align-items-center font-weight-4">
              TAX
            </span>
            <span class="w-50 p-2 pl-3 text-body-3 text-right font-weight-4">
              $ {{ total.tax | toMoney }}
            </span>
          </div>

          <AppDivider color="gray-dark-3"></AppDivider>

          <div class="d-flex justify-content-between align-content-center">
            <span class="text-caption d-flex align-items-center font-weight-4">
              TOTAL
            </span>
            <h4
              class="w-50 p-2 pl-3 text-right font-weight-6 font-style-primary"
            >
              $ {{ total.total | toMoney }}
            </h4>
          </div>
        </AppCard>
      </div>
    </div>

    <!-- SEND QUOTE Action Btns -->
    <div
      v-if="!quote || Object.keys(quote).length === 0"
      class="mt-4 d-flex flex-column flex-md-row justify-content-end"
    >
      <AppBtn
        outline
        :loading="loading"
        @click="$router.push({ name: 'OrderRequestList' })"
      >
        CONTINUE LATER
      </AppBtn>
      <AppBtn
        class="mt-2 mt-md-0 ml-md-2"
        :disabled="products.length !== proposedProducts.length || !deliveryDate"
        :loading="loading"
        @click="confirmProposal = true"
      >
        SEND QUOTATION
      </AppBtn>
    </div>
    <!-- RESEND QUOTE Action Btns -->
    <div v-else class="mt-4 d-flex flex-column flex-md-row justify-content-end">
      <AppBtn
        v-if="isDeletable"
        outline
        :loading="loading"
        @click="confirmDeleteQuote = true"
      >
        DELETE
      </AppBtn>
      <AppBtn
        class="mt-2 mt-md-0 ml-md-2"
        v-if="
          !edit &&
          ['completed', 'quote_approved'].includes(order.status) === false
        "
        @click="edit = true"
      >
        EDIT AND RESEND QUOTATION
      </AppBtn>
      <AppBtn
        class="ml-md-2"
        v-if="edit"
        outline
        :loading="loading"
        @click="edit = false"
      >
        CANCEL
      </AppBtn>
      <AppBtn
        v-if="edit"
        class="mt-2 mt-md-0 ml-md-2"
        :disabled="products.length !== proposedProducts.length || !deliveryDate"
        :loading="loading"
        @click="confirmProposal = true"
      >
        RESEND QUOTATION
      </AppBtn>
    </div>

    <!-- Order Propose Modal -->
    <OrderProposeModal
      v-model="proposeProduct"
      :product="productToMatch"
      :existingId="existingId"
      :loading="loading"
      @productProposal="setProductProposal"
    ></OrderProposeModal>
    <!-- File Drawer -->
    <AttachedFileDrawer
      v-model="attachmentDrawer"
      :files="attachmentFiles"
    ></AttachedFileDrawer>
    <!-- Confirm Proposal Modal -->
    <ActionModal v-model="confirmProposal" @confirm="sendQuotation">
      <template v-slot:title>
        Are you sure you want to send this quotation
      </template>
      <template v-slot:description> Click 'confirm' to proceed. </template>
    </ActionModal>
    <!-- Success Proposal Modal -->
    <SuccessModal v-model="successProposal" @confirm="$router.go()">
      <template v-slot:title> Quotation Sent </template>
      <template v-slot:description>
        Your quotation has been sent to the buyer. Please wait for the buyer to
        approve your quotation.
      </template>
      <template v-slot:action-btn-text> Close </template>
    </SuccessModal>

    <ActionModal v-model="confirmDeleteQuote" @confirm="deleteProposal">
      <template v-slot:title>
        Are you sure you want to delete this quotation
      </template>
      <template v-slot:description> Click 'confirm' to proceed. </template>
    </ActionModal>

    <SuccessModal v-model="deleteQuoteSuccessModal" @confirm="$router.go()">
      <template v-slot:title> Quotation Deleted </template>
      <template v-slot:description> Your quotation has been deleted. </template>
      <template v-slot:action-btn-text> Close </template>
    </SuccessModal>
  </div>
</template>

<script>
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppCard from '@/shared/elements/AppCard.vue';
import OrderProposeModal from './OrderProposeModal.vue';
import AppTextLabeled from '../../../shared/elements/AppTextLabeled.vue';
import AppIcon from '../../../shared/elements/AppIcon.vue';
import AppDivider from '../../../shared/elements/AppDivider.vue';
import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';
import AppInputCurrency from '../../../shared/elements/AppInputCurrency.vue';
import ActionModal from '../../../components/Partial/Modals/ActionModal.vue';
import Orders from '@/shared/api/Orders';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import _strings from '@/shared/mixins/_strings';
import AttachedFileDrawer from '../../Partial/Orders/AttachedFileDrawer.vue';
import AppInputDateTime from '../../../shared/elements/AppInputDateTime.vue';
import SuccessModal from '../../Partial/Modals/SuccessModal.vue';
export default {
  name: 'VendorOrderProposeTable',

  mixins: [_appApiHelper, _strings],

  components: {
    AppBtn,
    AppCard,
    OrderProposeModal,
    AppTextLabeled,
    AppIcon,
    AppDivider,
    AppMediaViewer,
    AppInputCurrency,
    ActionModal,
    AttachedFileDrawer,
    AppInputDateTime,
    SuccessModal,
  },

  props: {
    order: { type: Object, default: () => ({}) },
    quote: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      proposeProduct: false,
      productToMatch: null,
      existingId: false,
      proposeProductIndex: -1,
      proposedProducts: !this.quote?.products
        ? []
        : this.quote.products.map((product) => ({
            price: product.price,
            product: { ...product.product, files: [] },
            quantity: product.quantity,
            variant: product.variant,
            quantityOption: product.quantity_description,
          })),
      deliveryDate:
        this.qoute?.delivery_date.slice(0, 10) ||
        this.order?.delivery_date.slice(0, 10),
      shippingFee: this.qoute?.shipping_address_fee || 0,
      truckFee: this.qoute?.truck_fee || 0,
      confirmProposal: false,
      successProposal: false,
      confirmDeleteQuote: false,
      deleteQuoteSuccessModal: false,
      products: this.order?.products,
      attachmentDrawer: false,
      attachmentFiles: [],
      edit: false,
    };
  },

  watch: {
    order(val) {
      this.products = val.products;
      this.deliveryDate = val.delivery_date.slice(0, 10);
    },
    quote(val) {
      this.proposedProducts = val.products.map((product) => ({
        id: product.id,
        price: product.price,
        product: { ...product.product, files: [] },
        quantity: product.quantity,
        variant: product.variant,
        quantityOption: product.quantity_description,
      }));
      this.truckFee = val.truck_fee;
      this.shippingFee = val.shipping_address_fee;
      this.deliveryDate = val?.delivery_date?.slice(0, 10);
    },
  },

  computed: {
    isDeletable() {
      return true;
    },
    total() {
      let totalPrices = 0;

      if (this.proposedProducts.length > 0) {
        totalPrices = this.proposedProducts.reduce((total, product) => {
          return total + product.price * product.quantity;
        }, 0);
      }
      const tax = 0;

      return {
        productTotal: totalPrices,
        totalDelivery: this.shippingFee + this.truckFee,
        tax,
        total: totalPrices + this.shippingFee + this.truckFee + tax,
      };
    },
  },

  filters: {
    thumbnail(value) {
      if (value && value?.files.length > 0) {
        const files = value.files;
        let thumb = value.files.find((file) => file.type === 'primary_image');
        return thumb?.filename || files[0]?.filename;
      } else {
        return null;
      }
    },
    fullAddress(value) {
      return `${value.address}, ${value.city}, ${value.state} ${value.zip_code}`;
    },
  },

  methods: {
    deleteProposal() {
      Orders.deleteQuote(this.quote.id).then(() => {
        this.deleteQuoteSuccessModal = true;
      });
    },
    viewAttachments(attachments) {
      this.attachmentFiles = attachments;
      this.attachmentDrawer = true;
    },
    setProductProposal(products) {
      const { proposed } = products;

      const orderProductIndex = this.proposeProductIndex;

      //Has no index or doesnt exist then add it
      if (
        orderProductIndex === -1 ||
        !this.proposedProducts[orderProductIndex]
      ) {
        this.proposedProducts.push(proposed);
        return;
      }

      let newProposedProducts = this.proposedProducts;
      const id = newProposedProducts[orderProductIndex].id;

      if (id) {
        newProposedProducts[orderProductIndex] = {
          ...proposed,
          id,
          edit: true,
        };
      } else {
        newProposedProducts[orderProductIndex] = proposed;
      }

      // refreshes the computed "total" property
      // for an altertnative to deep watching
      // reset the value
      this.proposedProducts = [];

      this.$nextTick(() => {
        this.proposedProducts = newProposedProducts;
      });
    },
    proposeProductForOrder(product, index = -1, existingId = false) {
      this.proposeProduct = true;
      this.productToMatch = product;
      this.proposeProductIndex = index;

      if (existingId >= 0) {
        this.existingId = existingId;
      }
    },
    async sendQuotation() {
      const proposedProducts = this.proposedProducts.map((product, index) => ({
        order_id: this.order.id,
        order_product_id: this.products[index].id,
        product_id: product.product.id,
        price: product.price,
        price_start: product.price,
        price_end: product.price,
        total_price: product.price * product.quantity,
        product_variant_id: product?.variant?.id,
        quantity_id: product?.quantityOption?.quantity_id,
        quantity: product.quantity,
        conversion: product?.quantityOption?.conversion | 1,
      }));

      // const user = this.$store.getters['auth/user'];
      const form = {
        delivery_date: this.deliveryDate,
        truck_fee: this.truckFee,
        shipping_address_fee: this.shippingFee,
        vendor_id: null,
        order_id: this.order.id,
        products: proposedProducts,
      };
      let response = null;
      if (this.quote?.id) {
        await this.proposedProducts.map(async (product, index) => {
          if (product.edit) {
            this.$store.dispatch('api/preventSuccessModal');
            await Orders.editQuoteProduct(product.id, proposedProducts[index]);
          }
        });
        this.$store.dispatch('api/preventSuccessModal');
        response = await Orders.editQuote(this.quote?.id, form);
      } else {
        this.$store.dispatch('api/preventSuccessModal');
        response = await Orders.sendQuote(form);
      }
      this.successProposal = response.status === 200;

      this.$emit('sent-quotation');
    },
  },
};
</script>

<style lang="scss" scoped></style>
