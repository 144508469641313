<template>
  <LayoutSuperadmin
    :sidebarActiveItem="{
      name: 'order',
      children: sideBarItem,
    }"
  >
    <template v-slot:title>
      {{ title }}
    </template>

    <div class="order-proposal p-2 pl-md-5 pr-md-5 h-100 bg-gray-light-4">
      <!-- Back Btn -->

      <GoBackBtn>Back to list</GoBackBtn>

      <div v-if="order">
        <!-- Order Details Row -->

        <OrderDetails :order="order" :quote="quote"></OrderDetails>

        <h4 class="mt-4 font-weight-4">STATUS</h4>

        <!-- Status Row -->

        <div class="w-100 order-proposal-status-container">
          <div class="w-100 overflow-auto">
            <div class="order-proposal-status-container-content row">
              <div class="col-12">
                <AppCard class="p-4">
                  <OrderStatusIndicator
                      :pending="order.created_at | readableDate(false)"
                      :approved="order.updated_at | readableDate(false)"
                      :quoteSent="
                      quote ? (quote.created_at || quote.completed_at || quote.approved_at) : '' | readableDate(false)
                    "
                      :quoteApproved="
                      quote ? quote.approved_at || quote.completed_at : '' | readableDate(false, true)
                    "
                      :completed="
                      quote
                        ? quote.completed_at
                        : '' | readableDate(false, true)
                    "
                  ></OrderStatusIndicator>
                </AppCard>
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 order-proposal-table-container">
          <div class="order-proposal-table">
            <OrderProposeTable
              :order="order"
              :quote="quote"
              @sent-quotation="fetchAllDetails"
            ></OrderProposeTable>
          </div>
        </div>
      </div>
    </div>
  </LayoutSuperadmin>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import _readableDateTime from '@/shared/mixins/_readableDateTime';
import OrderStatusIndicator from '@/components/Vendor/OrderView/OrderStatusIndicator.vue';
import OrderProposeTable from '@/components/Vendor/OrderView/OrderProposeTable.vue';
import OrderDetails from '@/components/Vendor/OrderView/OrderDetails.vue';
import Orders from '@/shared/api/Orders';
import LayoutSuperadmin from '../../../../components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
import { STATUS } from '@/shared/constants/OrderConstants';

export default {
  name: 'SuperVendorOrderView',

  mixins: [_appApiHelper, _readableDateTime],

  components: {
    AppCard,
    OrderStatusIndicator,
    OrderProposeTable,
    GoBackBtn,
    OrderDetails,
    LayoutSuperadmin,
  },

  data() {
    return {
      id: this.$route.params.id,
      order: null,
      quote: null,
    };
  },

  watch: {
    $route: {
      handler() {
        this.id = this.$route.params.id;
        this.getOrderDetails();
        this.fetchExistingQuotes();
      },

      deep: true,
    },
  },

  methods: {
    async getOrderDetails() {
      const apiResponse = await Orders.getOrder(this.id);

      this.order = apiResponse.data;
    },

    async fetchExistingQuotes() {
      const apiResponse = await Orders.getQuotes({
        params: { order_id: this.id },
      });

      const existingQuote = apiResponse.data.data;

      if (existingQuote.length > 0) {
        this.quote = existingQuote[0];
      }
    },

    async fetchAllDetails() {
      await this.getOrderDetails();

      await this.fetchExistingQuotes();
    },
  },

  computed: {
    title() {
      if (
        [STATUS.COMPLETED.value, STATUS.QUOTE_APPROVED.value].includes(
          this.order?.status
        )
      ) {
        return this.order.status.replace('_', ' ').toUpperCase();
      }

      return this.quote
        ? 'PENDING BUYER APPROVAL'
        : 'IN PROGRESS QUOTE REQUEST';
    },
    sideBarItem() {
      const title = this.title.toLowerCase();

      return [
        title.includes('approved')
          ? 'accepted'
          : title.includes('completed')
          ? 'completed'
          : title.includes('pending')
          ? 'quoted'
          : 'approved',
      ];
    },
  },

  async mounted() {
    await this.fetchAllDetails();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';

@import '@/scss/theme/_colors';

.order-proposal {
  overflow-x: hidden;

  overflow-y: auto;

  &-back-btn {
    cursor: pointer;
  }

  &-table {
    @include media-md('above') {
      min-width: 900px;
    }

    &-container {
      overflow-x: auto;

      overflow-y: hidden;
    }
  }

  &-status-container {
    /* width */

    ::-webkit-scrollbar {
      height: 0.5rem !important;
    }

    ::-webkit-scrollbar-track {
      background-color: var(--primary-light-6) !important;

      // box-shadow: inset 1px 1px 12px -7px rgba(0, 0, 0, 0.371);
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
      border-radius: 60px !important;

      background-color: var(--primary) !important;
    }

    /* Handle on hover */

    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--primary-dark-3) !important;
    }

    &-content {
      width: 100%;

      @include media-md('below') {
        min-width: 1200px;
      }
    }
  }
}
</style>
