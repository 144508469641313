<template>
  <!-- Edit Product Modal -->
  <AppModal v-model="innerVal" skeletal width="994px" class="overflow-auto">
    <div class="w-100">
      <!-- Order Card -->
      <AppCard v-if="product">
        <div class="p-3">
          <div class="d-flex justify-content-between">
            <h4 class="d-none d-md-block">QUOTE FOR</h4>
            <h5 class="d-md-none">ORDER SUMMARY</h5>
            <AppIcon hoverable name="close" @click="innerVal = false"></AppIcon>
          </div>
          <div>
            <!-- Header -->
            <div class="row mt-3 bg-gray-light-4 d-none d-md-flex">
              <div class="col-4">Product</div>
              <div v-if="product && product.variant" class="col-3">
                Variants
              </div>
              <div class="col-2">Qty</div>
              <div class="col-3">Notes</div>
            </div>

            <div class="row mt-3">
              <!-- Product Name and Img -->
              <div class="col-12 col-md-4">
                <AppMediaViewer
                  width="42px"
                  aspectRatio="1/1"
                  :src="product.product | thumbnail"
                ></AppMediaViewer>
                {{ product.product.name }}
              </div>
              <div
                v-if="product && product.variant"
                class="
                  col-12 col-md-3
                  ml-5 ml-md-0
                  mt-2 mt-md-0
                  text-body-3 text-body-md-1
                "
              >
                <span class="font-weight-5">{{ product.variant.name }}</span>
              </div>
              <div
                class="
                  col-5 col-md-2
                  ml-5 ml-md-0
                  mt-2 mt-md-0
                  text-body-3 text-body-md-1
                "
              >
                <div v-if="product.quantity_description">
                  <span>
                    {{
                      `${product.quantity} ${product.quantity_description.name}`
                    }}
                  </span>
                  <span class="d-inline-block text-caption"
                    >({{
                      `${
                        parseInt(product.quantity) *
                        product.quantity_description.conversion
                      } pieces`
                    }})</span
                  >
                </div>
                <span v-else>{{ `${product.quantity} pcs` }}</span>
              </div>
              <div
                v-if="product && product.note"
                class="
                  col-12 col-md-3
                  ml-5 ml-md-0
                  mt-2 mt-md-0
                  text-body-3 text-body-md-1
                "
              >
                <span class="text-small d-md-none">Note:</span>
                <span class="font-weight-5">
                  {{ product.note }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </AppCard>
      <!-- Proposal Card  -->
      <AppCard class="mt-3">
        <div class="p-3">
          <h4 class="d-none d-md-block">CHOOSE MATERIALS & SUPPLY</h4>
          <h5 class="d-md-none">PROPOSAL</h5>

          <div class="mt-3">
            <!-- Product -->
            <AppInputSelect
              v-model="chosenProduct"
              searchable
              label="Product"
              :items="products"
            >
              <template v-slot:prepend-icon>
                <AppMediaViewer
                  v-if="chosenProduct"
                  width="40px"
                  aspect-ratio="1/1"
                  class="mr-2"
                  :src="chosenProduct | thumbnail"
                ></AppMediaViewer>
              </template>
              <template v-slot:option-item="{ item }">
                <div class="p-2 d-flex align-items-center">
                  <AppMediaViewer
                    width="40px"
                    aspect-ratio="1/1"
                    :src="item.value | thumbnail"
                  ></AppMediaViewer>
                  <span class="ml-3 font-weight-5">{{ item.text }}</span>
                </div>
              </template>
            </AppInputSelect>
            <div
              v-if="chosenProduct && chosenProduct.variants.length > 0"
              class="row"
            >
              <div class="col-12 col-md-6 d-flex">
                <AppInputSelect
                  v-model="variant"
                  class="w-100"
                  label="Variant"
                  :items="
                    filterApIVariant(chosenProduct.variants).map((variant) => ({
                      text: variant.name,
                      value: variant,
                    }))
                  "
                ></AppInputSelect>
              </div>
            </div>
            <div class="row mt-md-2">
              <!-- Quantity -->
              <div class="col-12 col-md-4">
                <label class="app-input-label">QTY</label>
                <div class="d-flex">
                  <AppInputSelect
                    v-if="chosenProduct && chosenProduct.quantities.length > 0"
                    v-model="quantityOption"
                    class="mr-2 w-100"
                    :items="
                      chosenProduct.quantities.map((qty) => ({
                        text: qty.quantity.name,
                        value: qty,
                      }))
                    "
                  ></AppInputSelect>
                  <AppInput
                    v-model="quantity"
                    min="1"
                    type="number"
                    class="w-100"
                  ></AppInput>
                </div>
              </div>
              <!-- Price -->
              <div class="col-12 col-md-4">
                <AppInputCurrency
                  v-model="price"
                  label="price"
                ></AppInputCurrency>
              </div>
              <!-- Total Amount -->
              <div class="col-12 col-md-4">
                <AppInputCurrency
                  readonly
                  label="total amount"
                  :value="totalPrice"
                ></AppInputCurrency>
              </div>
            </div>
          </div>
          <!-- Action btns -->
          <div class="d-flex flex-column flex-md-row justify-content-md-end">
            <AppBtn outline class="order-1 order-md-0" @click="closeModal">
              Cancel
            </AppBtn>
            <AppBtn
              class="mb-2 mb-md-0 ml-md-2"
              :disabled="!chosenProduct"
              @click="confirmProposedProduct"
            >
              Confirm Quote
            </AppBtn>
          </div>
        </div>
      </AppCard>
    </div>
  </AppModal>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppModal from '@/shared/elements/AppModal.vue';
import AppCard from '@/shared/elements/AppCard.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppInputSelect from '../../../shared/elements/AppInputSelect.vue';
import Products from '@/shared/api/Products';
import AppInput from '../../../shared/elements/AppInput.vue';
import AppInputCurrency from '../../../shared/elements/AppInputCurrency.vue';
import { filterApIVariant } from '@/shared/utils/variantsHelper';

export default {
  name: 'VendorOrderProposeModal',

  components: {
    AppModal,
    AppCard,
    AppIcon,
    AppBtn,
    AppMediaViewer,
    AppInputSelect,
    AppInput,
    AppInputCurrency,
  },

  mixins: [_appDefaultInput],

  props: {
    existingId: { type: [String, Number, Boolean], default: false },
    product: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      products: [],
      chosenProduct: null,
      variant: null,
      quantityOption: null,
      quantity: 1,
      price: 0,
    };
  },

  computed: {
    totalPrice() {
      if (this.price && this.quantity) {
        return this.price * this.quantity;
      } else {
        return 0;
      }
    },
  },

  watch: {
    chosenProduct(value) {
      this.variant = null;
      this.quantityOption = null;
      this.price = value?.price_start || value?.price_end || value?.price_fixed;
    },
    variant(variant) {
      if (variant) {
        this.price = variant?.price_fixed;
      }
    },
    quantityOption(qty) {
      if (this.existingId && this.product.price) {
        this.price = this.product.price;
        return;
      }

      if (!this.variant && qty !== null && Object.keys(qty).length !== 0) {
        this.price =
          qty.price_fixed || qty.price_start || qty.price_end || this.price;
      }
    },
    existingId(id) {
      if (this.products.length > 0 && id >= 0) {
        const product = this.products.find((prdct) => prdct.value.id === id);

        if (product) {
          this.chosenProduct = product.value;

          this.$nextTick(() => {
            this.quantity = this.product.quantity;
            if (this.product.variant) {
              this.variant = this.product.variant;
            }

            if (this.product.quantityOption) {
              this.quantityOption = this.product.quantityOption;
            }

            if (this.product.price) {
              this.price = this.product.price;
            }
          });
        }
      }
    },
    value(val) {
      if (val) {
        this.setProduct();
      }
    },
  },

  filters: {
    thumbnail(value) {
      if (value && value?.files.length > 0) {
        const files = value.files;
        let thumb = value.files.find((file) => file.type === 'primary_image');
        return thumb?.filename || files[0]?.filename;
      } else {
        return null;
      }
    },
  },

  methods: {
    filterApIVariant,
    async fetchProducts() {
      const response = await Products.getProducts();
      this.products = response.data.map((product) => ({
        text: product.name,
        value: product,
      }));
    },
    closeModal() {
      this.innerVal = false;
      this.chosenProduct = null;
      this.variant = null;
      this.quantityOption = null;
      this.quantity = 1;
      this.price = 0;
    },
    confirmProposedProduct() {
      // before sending ,check if quantityOption.quantity.name is set
      let quantity = this.quantityOption?.quantity?.name

      if (quantity !== null || quantity !== undefined) {
        // set variant to proper version
        this.chosenProduct.variants.forEach((variant) => {
          if (variant.name == this.variant.name) {
            if (variant.quantity_option === quantity) {
              this.variant = variant;
            }
          }
        })
      }
      const products = {
        proposed: {
          id: this.chosenProduct.id,
          product: this.chosenProduct,
          variant: this.variant,
          quantityOption: this.quantityOption,
          quantity: this.quantity,
          price: this.price,
        },
        order: this.product,
      };

      this.$emit('productProposal', products);
      this.closeModal();
    },
    setProduct() {
      if (!this.product) {
        return;
      }

      this.price = this.product.price ? this.product.price : this.price;

      setTimeout(() => {
        const existingQty = this.chosenProduct.quantities.find(
          (qty) =>
            this.product.quantityOption.name === qty.quantity.name ||
            this.product.quantityOption.id === qty.quantity.id
        );

        if (existingQty) {
          this.quantityOption = existingQty;
        }

        const existingVariant = this.chosenProduct.variants.find(
          (variant) =>
            this.product.variant.name === variant.name ||
            this.product.variant.id === variant.id
        );

        if (existingVariant) {
          this.variant = existingVariant;
        }
      }, 1000);

      this.quantity = this.product.quantity;
    },
  },

  async mounted() {
    await this.fetchProducts();
    this.setProduct();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_inputs';
</style>
